<template>
  <div>
    <div class="p-3" :class="{ 'register-box': !isMobile }">
      <b-row>
        <b-col
          md="6"
          v-for="(field, index) in dynamicField"
          :key="'dynamic-' + index"
        >
          <div v-if="field.field_type_id == 1">
            <InputText
              v-if="!isLoading"
              :textFloat="field.field_language[0].name"
              :placeholder="field.field_language[0].name"
              class="margin-inputf-regular mb-2"
              :type="checkFieldConditon(field)"
              name="firstname"
              v-model:value="field[keyValue]"
              :maxLength="checkMaxMin(field.field_conditions, 2)"
              :min="checkMaxMin(field.field_conditions, 3)"
              :max="checkMaxMin(field.field_conditions, 4)"
              :disabled="field.field_profile_type_id == 10"
              @onDataChange="isMaxMin"
              :index="index"
              @onkeypress="isNumber"
            />
          </div>

          <div
            v-else-if="
              field.field_type_id == 2 && field.field_profile_type_id != 17
            "
          >
            <div class="input-custom mt-2">
              <label class="title">
                {{ field.field_language[0].name }}
              </label>

              <b-form-checkbox-group
                :id="`${field.id}`"
                size="lg"
                v-model="field[keyValue]"
                :options="
                  field.field_choices.map((x) => ({
                    text: x.name,
                    value: x.name,
                  }))
                "
                class="ml-2 mb-2"
                name="text"
                :value="field[keyValue]"
              ></b-form-checkbox-group>
            </div>
          </div>
          <div v-else-if="field.field_profile_type_id == 17">
            <div class="input-custom">
              <b-form-checkbox-group
                size="lg"
                class="ml-2 mb-2"
                v-model="field[keyValue]"
                :options="[{ name: 'Set to default', value: 1 }]"
                name="name"
                :value="field[keyValue]"
              ></b-form-checkbox-group>
            </div>
          </div>
          <div v-else-if="field.field_profile_type_id == 17">
            <div class="input-custom">
              <b-form-checkbox-group
                size="lg"
                class="ml-2 mb-2"
                v-model="field[keyValue]"
                :options="[{ name: 'Set to default', value: 1 }]"
                name="name"
                :value="field[keyValue]"
              ></b-form-checkbox-group>
            </div>
          </div>
          <div v-else-if="field.field_type_id == 4">
            <InputSelect
              :title="field.field_language[0].name"
              :options="[
                {
                  text: field.field_language[0].name,
                  value: '',
                  disabled: true,
                },
                ...field.field_choices.map((x) => ({
                  text: x.name,
                  value: x.name,
                })),
              ]"
              v-model="field[keyValue]"
              class="f-regular mb-2"
              @onDataChange="
                      (val) =>
                      field[keyValue] =
                          (val),
                    "
            >
            </InputSelect>
          </div>
          <div v-else-if="field.field_type_id == 3">
            <InputRadioGroup
              :textFloat="field.field_language[0].name"
              v-model="field[keyValue]"
              class="mt-2 mb-2"
              :options="
                field.field_choices.map((x) => ({
                  text: x.name,
                  value: x.name,
                }))
              "
              @onDataChange="(val) => (field[keyValue] = val)"
            />
          </div>
          <div v-else-if="field.field_type_id == 7">
            <datetime
              type="date"
              :placeholder="field.field_language[0].name"
              :class="['date-picker-input date-filter']"
              :format="'dd/MM/yyyy'"
              value-zone="Asia/Bangkok"
              v-model="field[keyValue]"
              @input="(val) => field[keyValue] = (val), "
              :maxDatetime="now"
              class="datetime-h mb-2"
            >
              <label for="startDate" slot="before" class="title"
                >{{ field.field_language[0].name }}
              </label>
            </datetime>
          </div>
          <div v-else-if="field.field_type_id == 5">
        
            <datetime
              type="datetime"
              :placeholder="field.field_language[0].name"
              :class="['date-picker-input date-filter']"
              :format="'dd/MM/yyyy HH:mm'"
              value-zone="Asia/Bangkok"
              v-model="field[keyValue]"
              @input="(val) => field[keyValue] = (val), "
              :maxDatetime="now"
              class="datetime-h mb-2"
             >
              <label for="startDate" slot="before" class="title"
                >{{ field.field_language[0].name }}
              </label>
            </datetime>
          </div>
          <div v-else-if="field.field_type_id == 6" class="input-custom">
            <InputSelect
              :title="field.field_language[0].name"
              :text="field.field_language[0].name"
              :name="field.field_language[0].name"
              :options="branchList.map((x) => ({ value: x.id, text: x.name }))"
              v-model:value="field[keyValue]"
              :isRequired="field[keyRequired]"
              class="f-regular mb-2"
              @onDataChange="(val) =>
                      field[keyValue] =
                          (val),
                    "
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >--
                  {{ field.field_language[0].name }} --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
          <div v-else-if="field.field_type_id == 10">
            <h5 class="mb-2">{{ field.field_language[0].name }}</h5>
          </div>
          <div v-else-if="field.field_type_id == 11">
            <h5 class="mb-2">{{ field.field_language[0].name }}</h5>
          </div>
          <div v-else-if="field.field_type_id == 8">
            <label class="title ">
              {{ field.field_language[0].name }}
            </label>

            <InputUploadImage
              :label="field.field_language[0].name"
              :img="field[keyValue]"
              v-model="field[keyValue]"
              @setFileName="
                (val) => setImageName(val, field.field_profile_type_id)
              "
              class="mb-2"
            />
          </div>
          <div v-else-if="field.field_type_id == 12">
            <InputTextArea
              class=""
              :placeholder="field.field_language[0].name"
              :textFloat="field.field_language[0].name"
              name="text"
              rows="3"
              v-model="field[keyValue]"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import InputSelect from "@/components/inputs/InputSelect";
import { province } from "@/assets/json/fieldAddress.js";
import InputTextArea from "@/components/inputs/InputTextArea";
import { mapGetters } from "vuex";
import InputUploadImage from "../inputs/InputUploadImage.vue";

export default {
  components: {
    province,
    InputSelect,
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    InputUploadImage,
    InputTextArea,
  },
  props: {
    keyValue: {
      type: String,
      default: () => "user_answer",
      required: false,
    },
    form: {
      type: Object,
      default: true,
    },
    dynamicField: {
      type: Array,
    },
    isMobile: { type: Boolean },
    keyRequired: {
      required: false,
      type: String,
      default: () => "required",
    },
  },
  data() {
    return {
      isLoading: false,
      branchList: [],
    };
  },

  validations() {},
  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    let branchField = this.dynamicField.find((el) => el.field_type_id == 6);

    if (branchField) {
      this.getBranch(branchField.branch_group_id);
    }
  },

  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  methods: {
    checkMaxMin(item, key, field_id) {
      if (!item) return false;
      if (field_id == 9) {
        const condition = item.find((el) => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? this.$moment()
                  .subtract(condition.value, "year")
                  .format("YYYY-01-01")
              : false;
          } else if (key == 4) return this.$moment().format("YYYY-MM-DD");
      }
      const conditon = item.find((el) => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (val && e.target.type == "number" && min && val < min) {
          return (e.target.value = min);
        }
        if (val && e.target.type == "number" && max && val > max) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    checkFieldConditon(obj) {
      if (!obj.field_conditions) return "text";
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    onDataChange(val, form) {
      form = val;
    },
    async getBranch(group_id) {
      this.$axios
        .get(`${this.$baseUrl}/customer/GetBranchByBranchGroup/${group_id}`)
        .then(
          async (data) => {
            this.isLoading = false;
            if (data.result == 1) {
              var branchList = [];
              for (const { branch } of data.detail) {
                const filter = branch.filter((el) => el.is_check);
                branchList.push(...filter);
              }
              this.branchList = branchList;
            }
          },
          (error) => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  position: relative;
}
.register-box {
  max-height: 500px;
  overflow: auto;
}
::v-deep .input-custom {
  white-space: wrap !important;
}
::v-deep .vdatetime-input {
  border-radius: 10px !important;
  /* padding: 8px 10px; */
  height: 40px !important;
}

.datetime-h {
  font-size: 14px !important;
  font-weight: 400;
  color: black;
}
</style>
